import React, { useState, useEffect, useContext } from 'react'
import { FiCreditCard } from "react-icons/fi";
import { ImPaypal } from "react-icons/im";
import { GiReceiveMoney } from "react-icons/gi";
import CartContext from './../../context/CartContext'
import OrderService from '../../services/Order/OrderService';
import { Link, useNavigate } from 'react-router-dom'
import PaymentForm from '../../components/Forms/PaymentForm';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import TPVService from '../../services/Order/TPVService';

const URL_REDSYS = process.env.REACT_APP_REDSYS;
const DISCOUNT_CODE = process.env.REACT_APP_DISCOUNT_CODE;

export default function CartPay({ showSectionPay, userCart, addressCart, addressBillingCart, payMethodCart, setPayMethodCart, addressIndependent }) {

  const { addCart, totalPrice, cart, removeCart, applyDiscountCode } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");
  const [observations, setObservations] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [tpvSignature, setTpvSignature] = useState("");
  const [tpvParameters, setTpvParameters] = useState("");
  const [payMethodSelected, setPayMethodSelected] = useState(false)
  const [checkConditions, setCheckConditions] = useState(false);
  const [isValidDiscountCode, setIsValidDiscountCode] = useState(null);
  const objectPayMethodInitial = [
    {
      key: 0,
      selected: false,
      method: "CREDIT_CARD",
      text: "Tarjeta de crédito / PayPal",
      icon: <FiCreditCard />
    },
    // {
    //   key: 1,
    //   selected: false,
    //   method: "BIZUM",
    //   text: "Bizum",
    //   icon: <img src="/images/reyraf/bizum.png" style={{width: '30px'}} alt="Logotipo de Bizum"/>
    // },
    // {
    //   key: 1,
    //   selected: false,
    //   method: "PAYPAL",
    //   text: "PayPal",
    //   icon: <ImPaypal />
    // },
    {
      key: 1,
      selected: false,
      method: "CONTRAREEMBOLSO",
      text: "Contrareembolso",
      icon: <GiReceiveMoney />
    }
  ];

  const [objectPayMethod, setObjectPayMethod] = useState(objectPayMethodInitial)
  let navigate = useNavigate()

  const prepareOrder = () => {
    return ({
      user: {
        id: userCart.id,
        name: userCart.name,
        phone: userCart.phone,
        email: userCart.email,
        role: userCart.role,
        new: userCart.newUser,
        cif: userCart.cif,
        password: userCart.password,
        passwordConfirm: userCart.passwordConfirm
      },
      addressIndependent: {
        street: addressIndependent.street,
        zip: addressIndependent.zip,
        town: addressIndependent.town,
        city: addressIndependent.city,
        country: ''
      },
      totalPrice: payMethodCart === "CONTRAREEMBOLSO" ? totalPrice + parseInt(2) : totalPrice,
      address: addressCart,
      addressBilling: addressBillingCart,
      payMethod: payMethodCart,
      observations: observations
    })
  }

  useEffect(() => {
   // console.log(cart);
    if (payMethodCart !== "")
      setPayMethodSelected(true);
  }, [cart])

  useEffect(() => {
    if (payMethodCart === "") {
      setObjectPayMethod(objectPayMethodInitial);
      setPayMethodSelected(false);
    }
    let order = prepareOrder();
    addCart(order, null, 0);
  }, [payMethodCart])

  useEffect(() => {
    if (tpvSignature !== "" && tpvParameters !== "") {
      removeCart();
      document.getElementById("formTPV").submit();
    }
  }, [tpvSignature, tpvParameters])

  useEffect(() => {
    applyDiscountCode(isValidDiscountCode);
  }, [isValidDiscountCode])

  const createOrder = async (cart) => {
    setIsLoading(true);
    setAlert("");
    await OrderService.create(cart).then(
      response => {
        setMessage("");
        setIsLoading(false);
        setAlert("success");
        removeCart();
        let order = response.content.order
        //if (order.status === "PAID") {
          navigate("/compra-satisfactoria/" + order.id + "/" + order.locator, { replace: true })
        //}
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setMessage(resMessage);
          setIsLoading(false);
          setAlert("danger");
      }
    );
  }

  const selectPayMethod = (key) => {
    let payMethods = [...objectPayMethod];
    console.log(payMethods)
    payMethods.map((p, keyP) => {
      if (keyP === key) {
        setPayMethodCart(p.method)
      }
      return p.selected = (keyP === key) ? true : false
    });
    setObjectPayMethod(payMethods);
  }

  const onHandlePay = () => {
    createOrder(cart);
  }

  const onHandlePayCreditCard = async () => {
    setIsLoading(true);
    setAlert("");
    await OrderService.create(cart).then(
      response => {
        // this.setState({ message: "" });
        // this.setState({ isLoading: false });
        // this.setState({ alert: "success" });
        let order = response.content.order
        TPVService.generateParametersTPV("", "", "", "", order.locator, order.total_price).then(
          response => {
            setTpvSignature(response.content.signature);
            setTpvParameters(response.content.params);

          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            setIsLoading(false);
            setAlert("danger");
          }
        )

        //if (order.status === "PAID") {
        //navigate("/compra-satisfactoria/" + order.id + "/" + order.locator, { replace: true })
        //}
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setIsLoading(false);
        setAlert("danger");
      }
    );
  }

  const handleCheckConditions = () => {
    setCheckConditions(!checkConditions);
  }

  const createOrderPayPal = (data, actions) => {
    let order = actions.order.create({
      purchase_units: [
        {
          amount: {
            //currency_code: "EUR",
            value: cart.order.total_price,
          },
        },
      ],
    });
    return order;
  };
  const onApprovePayPal = (data, actions) => {
    createOrder(cart);
    return actions.order.capture();
  };

  const onCancelPayPal = (data) => {

  }

  const onChangeObservations = (e) => {
    setObservations(e.target.value)
  }

  const onChangeDiscountCode = (e) => {
    setDiscountCode(e.target.value)
  }

  const applyDiscount = () => {
    if (DISCOUNT_CODE === discountCode)
    {
      setIsValidDiscountCode(true);
    }
    else
    {
      setIsValidDiscountCode(false);
    }
  }

  return (
    <section data-name="section-pay" className={showSectionPay ? "checkoutStep checkoutStep__open" : "checkoutStep"}>
      <div className="checkoutStep__main">
        <div className="checkoutStep__col">
          <h2 className="checkoutStep__title">3. Pago</h2>
          <span className="checkoutStep__description"></span>
        </div>
        <div className="checkoutStep__col">

        </div>
      </div>
      <div className="checkoutStep__content">
        <div className="cardAccount__formGroup">
          <label htmlFor="observations" className="cardAccount__label">Observaciones</label>
          <textarea className="cardAccount__input" onChange={onChangeObservations} name="observations" id="observations" rows="4"></textarea>
        </div>
        <div className="checkoutStep__check">

          <input type="checkbox" onChange={handleCheckConditions} /> He leído y acepto las <Link to="/condiciones-generales-contrato" target="_blank" title="Leer las condiciones">condiciones de compra</Link> y la <Link to="/garantia-venta-bienes" target="_blank" title="Leer las garantías en la venta de bienes de consumo">información sobre las garantías en la venta de bienes de consumo</Link>
        </div>
        <div className="checkoutStep__keypad">

            {objectPayMethod && objectPayMethod.length > 0 && objectPayMethod.map((payMethod, key) => {
              return (
                <div key={key} className="checkoutStep__button">
                  <button className={payMethod.selected ? "checkoutStepButton checkoutStepButton--selected": "checkoutStepButton"} onClick={() => selectPayMethod(payMethod.key)}>
                    <div className="checkoutStepButton__icon">{payMethod.icon}</div>
                    <div className="checkoutStepButton__text">{payMethod.text}</div>
                  </button>
                </div>
              )
            })}
        </div>
        <div>
          <div className="cardAccount__formGroup">
            <label htmlFor="discountCode" className="cardAccount__label cardAccount__label--discount">Código descuento</label>
            <input id="discountCode"
              type="text"
              className="cardAccount__input cardAccount__input--discount"
              name="discountCode"
              placeholder="Indique un código"
              value={discountCode}
              onChange={onChangeDiscountCode}
            />
            <button className="checkoutStepTarget__formSubmit checkoutStepTarget__formSubmit--discount" onClick={() => applyDiscount()}>Aplicar descuento</button>
            {(isValidDiscountCode !== null && isValidDiscountCode) && <p style={{color: "green"}}>Código descuento correcto</p>}
            {(isValidDiscountCode !== null && !isValidDiscountCode) && <p style={{color: "red"}}>Código descuento incorrecto</p>}
          </div>
        </div>
        <div className="checkoutStepTarget__formColumn">
          {message && (
            <div className="cardAccount__formGroup">
              {alert && alert === "danger" && (
                <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                  {message}
                </div>)}
            </div>
          )}
          {
            (!payMethodSelected || !checkConditions) && <span>Seleccionar método de pago y aceptar las condiciones</span>
          }          
          <form id="formTPV" action={URL_REDSYS} name="from" method="POST">
            <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
            <input type="hidden" name="Ds_MerchantParameters" value={tpvParameters} />
            <input type="hidden" name="Ds_Signature" value={tpvSignature} />
          </form>
          {payMethodSelected && checkConditions && !isLoading &&
            <React.Fragment>
              {payMethodCart === "CREDIT_CARD" &&
                <React.Fragment>
                  <div onClick={onHandlePayCreditCard} className="checkoutStepTarget__formColumn">
                    <button className="checkoutStepTarget__formSubmit">Pagar</button>
                  </div>
                  {/* <PaymentForm cart={cart} removeCart={removeCart}/> */}
                </React.Fragment>
              }
              {payMethodCart === "PAYPAL" &&
              <div className="paypal">
                <PayPalScriptProvider options={{ "client-id": "Af2-bZSVmAphrNrIQNwVL_W8k0DkeX6q-tx7zIKjso5N6Y1kN2zfG6iGuudG5qbnVDKWzoh_fkDRgNLo", "currency": "EUR" }}>
                    <PayPalButtons
                      style={{ layout: "horizontal", tagline: false }}
                      createOrder={(data, actions) => createOrderPayPal(data, actions)}
                      onApprove={(data, actions) => onApprovePayPal(data, actions)}
                      onCancel={(data) => onCancelPayPal(data)}
                    />
                  </PayPalScriptProvider>
                </div>
            }
              {payMethodCart === "CONTRAREEMBOLSO" &&
                <React.Fragment>
                  <span className="checkoutText">* El método de pago contrareembolso incluye un suplemento de 2€ por gestión.<br /><strong>Total con suplemento: {totalPrice + parseInt(2)} €</strong></span>
                  <button className="checkoutStepTarget__formSubmit" onClick={onHandlePay}>Pagar</button>
                </React.Fragment>}
            </React.Fragment>
          }
          {payMethodSelected && checkConditions && isLoading &&
            <React.Fragment>
              <span className="spinner-border"></span> Procesando compra...
            </React.Fragment>
          }
        </div>
        {/* <PaymentForm /> */}
      </div>
    </section>
  )
}